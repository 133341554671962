import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/app/datadog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/components/auth-wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/components/bug-report-info.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/components/important-messages/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/components/intercom/intercom-wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/components/polyfills/polyfill-wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/root/repo/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider"] */ "/root/repo/components/ui/tooltip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WindowKeeperTabs"] */ "/root/repo/components/window-keeper/window-keeper-tabs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalBeforeUnloadListener"] */ "/root/repo/lib/global-before-unload.tsx");
;
import(/* webpackMode: "eager" */ "/root/repo/node_modules/.pnpm/next@14.2.25_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/root/repo/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["AuthContextElement"] */ "/root/repo/providers/auth-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BookmarkProvider"] */ "/root/repo/providers/bookmark-provider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BrandingProvider"] */ "/root/repo/providers/branding-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModalProvider"] */ "/root/repo/providers/modal-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PromptContextElement"] */ "/root/repo/providers/promptProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/providers/queryClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/root/repo/providers/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WebsocketProvider"] */ "/root/repo/providers/websocket-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/providers/windowkeeperProvider.tsx");
